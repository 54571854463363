.cardLink {
    position: relative;
    transition: .25s;
    border-radius: 15px;
    display: flex;
    text-align: center;
}

.cardTitle
{
    transition: .25s;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-bg2);
    font-weight: 700;
    font-size: 1.5rem;
    font-family: "Oswald", sans-serif;
}

.cardImage {
    transition: .25s;
    pointer-events: none;
    border-radius: 15px;
    border: 3px solid var(--color-bg1-highlight);
}

.cardLink:hover {
    background-color: white;
    transform: scale(1.025);
}

.cardLink:hover > .cardTitle {
    opacity: 1;
}

.cardLink:hover > .cardImage {
    border-color: var(--color-white);
    filter: opacity(.1);
}


.tagRow {
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0;
}

.tag {
    background-color: white;
    border-radius: 5px;
    padding: 0 10px;
    font-size: .85rem;
    text-align: center;
    border: 1px solid var(--color-bg2-highlight);
}

/* Tags */
.type {
    background-color: #a60b0b;
}

.software {
    background-color: #813772;
}

.language {
    background-color: #0f579f;
}

.role {
    background-color: #11a369;
}

.company {
    background-color: #a35511;
}