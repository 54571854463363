#lightbox-wrapped-content {
    cursor: zoom-in;
}

#lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}

#lightbox-image {
    cursor: zoom-out;
    object-fit: contain;
    width: 75%;
    height: 75%;
    max-width: 75%;
    max-height: 75%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
}
