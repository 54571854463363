.backgroundCarousel, .backgroundCarousel > *, .backgroundCarousel > * > * {
    width: 100%;
    height: 100%;
    z-index: -1;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.boxshadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.75;
}