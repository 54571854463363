/* Profile Section */
#profile-image {
    border-radius: 100px;
    pointer-events: none;
    border: 5px solid var(--color-accent);
    max-height: 500px;
}

/* Skills Section */
#skill-row > img, #tech-row > img {
    display: grid !important;
    align-content: center!important;
    width: auto;
}

#tech-row > img {
    max-height: 7vmax;
}

#skill-row > img {
    max-height: 5.5vmax;
}

/* Resume Section */
#resume-context a {
    width: 100px;
    margin: 0 1vmin;
}

#resume-context a * {
    width: 100%;
}

#resume-view {
    min-height: 100vmin;
    min-width: 100%;
}