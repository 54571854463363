:root {
    color-scheme: dark;
    --color-bg1: #191a20;
    --color-bg1-highlight: #3a3a3a;
    --color-bg2: #000000;
    --color-bg2-highlight: #0e0e0e;
    --color-white: #FFFFFF;
    --color-accent: #6ED3F4;
    --color-accent-dark: #183869;
    --color-accent-highlight: #b9eeff;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
    overscroll-behavior: none;
}

body {
    background-color: var(--color-bg1);
    color: var(--color-white);
    font-family: 'Heebo', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4 {
    font-weight: bold;
    margin: 0;
}

@media only screen and (max-width: 1000px) {
    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 1.75rem;
    }
    
    h3 {
        font-size: 1.4rem
    }

    h4 {
        font-size: 1.3rem
    }

    p {
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: 700px) {
    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.25rem;
    }

    h3, h4 {
        font-size: 1.1rem
    }

    p {
        font-size: 1rem;
    }
}

a {
    color: var(--color-accent);
    text-decoration: none;
}

a:hover {
    color: var(--color-accent-highlight)
}

.alt-text {
    color: var(--color-accent);
}

.alt-bg {
    background-color: var(--color-bg2);
}

.center {
    text-align: center;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
}

/* React Components */
.list-group {
    --bs-list-group-bg: var(--color-bg2-highlight);
    --bs-list-group-color: var(--color-white);
    --bs-list-group-border-color: var(--color-bg1-highlight);
    --bs-list-group-action-hover-bg: var(--color-bg1-highlight);
    --bs-list-group-action-hover-color: var(--color-white)
}

/* Bootstrap Components */
.btn-primary {
    font-weight: bold;
    color: var(--color-white);
    background-color: var(--color-accent-dark);
    border-color: var(--color-bg1);
}

.btn-primary:hover {
    background-color: var(--color-accent);
    border-color: var(--color-accent);
}

/* Generic Components */
.bar {
    width: 10vmax;
    margin: 2.5vh;
    height: 0.5vmin;
    background-color: var(--color-white);
    border-radius: 2px;
}

/* Device Dependent Content */
@media only screen and (max-width: 700px) {
    .large-screens-only {
        display: none;
    }
}