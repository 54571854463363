.background-container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

#project-title {
    color: var(--color-accent)
}

.content-container {
    padding: 80px 0 30px 0;
}

.content-box {
    background-color: var(--color-bg1);
    opacity: 0.95;
    border-radius: 20px;
}

.content-box img {
    border: 2px solid var(--color-bg1-highlight);
}

/* React Bootstrap Components */
.accordion {
    margin-bottom: 1rem;
}

.accordion-button {
    background-color: #4F4669 !important;
    color: white;
    font-weight: bold;
    border: 0 !important;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: white;
}

.accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion pre {
    margin: 0;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0px;
    padding-top: 56.25%;
}

.video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid var(--color-bg1-highlight);
}