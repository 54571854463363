/* Header */
#header {
    transition: 0.25s;
    padding: 0vh 0vw;
}

@media (hover: hover) {
    #header:hover {
        padding: 1vh 1vw;
    }
}

.navbar {
    background-color: var(--color-bg1) !important;
    color: var(--color-white);
    padding: .5rem 1rem;
}

@media (hover: hover) {
    .navbar {
        transition: 0.2s;
        opacity: 0.75;
    }

    .navbar:hover {
        opacity: 1;
    }
}

.navbar a {
    color: var(--color-white);
    text-decoration: none;
}

.navbar a:hover {
    color: var(--color-accent);
}

.navbar a:focus {
    color: var(--color-accent);
    outline: none;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

#header-title {
    color: var(--color-accent);
}

/* Buffer */
.navbar-buffer {
    height: 3.5rem;
    display: block;
}