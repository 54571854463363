#footer {
    background-color: var(--color-bg1);
}

#contact-row > * {
    display: grid !important;
    align-content: center!important;
    width: auto;
}

.contact-icon {
    max-height: 48px;
}

.contact-icon:hover {
    filter: invert(49%) sepia(100%) saturate(1271%) hue-rotate(160deg) brightness(102%) contrast(106%);
    cursor: pointer;
}