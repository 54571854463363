.summaryBox {
    background-color: var(--color-bg2);
}

.summaryBox
{
    font-size: small;
}

.summaryBox .lineHeader
{
    color: var(--color-accent);
    font-size: large;
}

.summaryBox p 
{
    margin-bottom: .5rem;
    font-size: medium;
}

.summaryBox
{
    color: white;
}

#summary-dropdown .accordion-item 
{
    background-color: var(--color-bg2-highlight);
    border-color: var(--color-bg2-highlight);
}

#summary-dropdown .accordion-header button
{
    background-color: var(--color-bg2) !important;
    color: var(--color-accent);
    height: 50px;
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--color-bg2-highlight);
}