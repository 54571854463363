#cardLinkRow {
    justify-content: center;
    row-gap: 16px;
    column-gap: 16px;
}

.storeLink {
    transition: .25s;
    border-radius: 15px;
    display: flex;
    max-width: 160px;
}

.storeImage {
    transition: .25s;
    pointer-events: none;
    border-radius: 15px;
    border: 3px solid var(--color-bg1-highlight);
}

.storeLink:hover > .storeImage {
    border-color: var(--color-white);
    filter: opacity(0.66);
}

.storeLink:hover {
    background-color: white;
    transform: scale(1.025);
}